import React, { Suspense, lazy, useEffect } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import Main from './layouts/Main'; // fallback for lazy pages
import './static/css/main.scss'; // All of our styles

const { PUBLIC_URL } = process.env;

const Redirect = ({ to }) => {
  useEffect(() => {
    window.location.replace(to);
  }, [to]);

  return null;
};

const NotFound = lazy(() => import('./pages/NotFound'));
const AxeAI = lazy(() => import('./pages/AxeAI'));
const AxeAIPrivacy = lazy(() => import('./pages/AxeAIPrivacy'));
const AxeAISupport = lazy(() => import('./pages/AxeAISupport'));
const AxeAITOS = lazy(() => import('./pages/AxeAITOS'));
const PaymentSuccess = lazy(() => import('./pages/PaymentSuccess'));
const PaymentFailure = lazy(() => import('./pages/PaymentFailure'));


const App = () => (
  <BrowserRouter basename={PUBLIC_URL}>
    <Suspense fallback={<Main />}>
      <Switch>
        <Route exact path="/" component={AxeAI} />
        <Route exact path="/privacy" component={AxeAIPrivacy} />
        <Route exact path="/support" component={AxeAISupport} />
        <Route exact path="/tos" component={AxeAITOS} />
        <Route exact path="/success" component={PaymentSuccess} />
        <Route exact path="/failure" component={PaymentFailure} />
        <Route path="/axeai/oauth">
          <Redirect to="https://sozroas404.execute-api.us-east-1.amazonaws.com/prod/axeai/oauth" />
        </Route>
        <Route component={NotFound} status={404} />
      </Switch>
    </Suspense>
  </BrowserRouter>
);

export default App;
